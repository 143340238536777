import client from '../../axios'

export default {
  state: {
    isLoggedIn: false,
    user: {}
  },
  mutations: {
    setLoggedIn (state, value) {
      state.isLoggedIn = value
    },
    setUser (state, payload) {
      state.user = payload
    }
  },
  actions: {
    async getUser ({ commit }) {
      const response = await client.get('/auth/me')
      commit('setUser', {
        name: response.data.name,
        email: response.data.email,
        addressType: response.data.addressType,
        billingName: response.data.billingName,
        zip: response.data.zip,
        city: response.data.city,
        address: response.data.address,
        phone: response.data.phone,
        companyName: response.data.companyName,
        taxNumber: response.data.taxNumber,
        afa: response.data.afa,
        newsletter: response.data.newsletter,
        completeProfile: response.data.completeProfile,
        deleteProfile: response.data.deleteProfile,
        favourites: response.data.favourites
      })
    },
    async checkIfLoggedIn ({ commit, dispatch }) {
      let loggedIn = false
      const response = await client.get('/auth/me')
      if (response.data === 'No user found') {
        loggedIn = false
      } else {
        commit('setUser', {
          name: response.data.name,
          email: response.data.email,
          addressType: response.data.addressType,
          billingName: response.data.billingName,
          zip: response.data.zip,
          city: response.data.city,
          address: response.data.address,
          phone: response.data.phone,
          companyName: response.data.companyName,
          taxNumber: response.data.taxNumber,
          afa: response.data.afa,
          newsletter: response.data.newsletter,
          completeProfile: response.data.completeProfile,
          deleteProfile: response.data.deleteProfile,
          favourites: response.data.favourites
        })
        loggedIn = true
        dispatch('car/getCars', {}, { root: true })
        dispatch('service/getServices', {}, { root: true })
        dispatch('service/getServiceTypes', {}, { root: true })
        dispatch('reservation/getReservations', {}, { root: true })
        /* Ilyet nem kell használnunk, user GET-je adja ezeket az adatokat
        dispatch('userData/getUserData', {}, { root: true })
        */
      }

      commit('setLoggedIn', loggedIn)

      return loggedIn
    },
    register (_, payload) {
      return client.post('/auth/register', payload)
    },
    login (_, payload) {
      return client.post('/auth/login', payload)
    },
    async logout ({ commit }) {
      await client.post('/auth/logout')
      commit('setLoggedIn', false)
    },
    async saveFavourite ({ state }, favourite) {
      await client.post('/favourites/add', favourite)
      state.user.favourites.push(favourite)
    },
    async removeFavourite ({ state }, favourite) {
      await client.post('/favourites/remove', favourite)
      state.user.favourites.splice(state.user.favourites.indexOf(favourite), 1)
    }
  },
  namespaced: true
}
