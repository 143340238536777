<template>
  <v-container class="profile-data mx-auto" style="width:400px" fluid>
    <v-dialog v-model="show" width="500"> <!-- persistent -->
      <v-form ref="form" @submit.prevent="save" lazy-validation>
        <v-card>
          <v-card-title class="tertiary mb-3 card-title">Profil szerkesztése
          <v-icon color="white" right>mdi-file-edit</v-icon>
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="message">
              <div>A profilod típusa:</div>
            </div>
            <v-radio-group class="mt-1" v-model="addressType" mandatory row>
              <v-radio label="Személyes" color="primary" value="personal"></v-radio>
              <v-radio label="Céges" color="primary" value="company"></v-radio>
            </v-radio-group>
            <v-text-field class="mt-0 pt-1" v-if="addressType === 'personal'" ref="name" v-model="billingName" label="Név" hint="A számlázási név a valós neved kell legyen." required :rules="[rules.billingName]" :error-messages="errors.billingName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="companyName" label="Cégnév" placeholder="Pl. Tuti Mosó Kft." required :rules="[rules.companyName]" :error-messages="errors.companyName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="taxNumber" label="Adószám" placeholder="Pl. 12345678-2-08" required :rules="rules.taxNumber" :error-messages="errors.taxNumber"></v-text-field>
            <v-text-field ref="zip" v-model="zip" label="Irányítószám" placeholder="Pl. 9026" required :rules="rules.zip" :error-messages="errors.zip"></v-text-field>
            <v-text-field ref="city" v-model="city" label="Település" placeholder="Pl. Győr" required :rules="[rules.city]" :error-messages="errors.city"></v-text-field>
            <v-text-field ref="address" v-model="address" label="Utca, házszám" placeholder="Pl. Mosós utca 11/B" required :rules="[rules.address]" :error-messages="errors.address"></v-text-field>
            <v-text-field v-model="phone" label="Telefonszám (opcionális)" placeholder="Pl. 06301234567" :rules="[rules.phone]" :error-messages="errors.phone"></v-text-field>
            <v-checkbox v-model="afa" color="primary" class="pt-0 mt-0">
              <template v-slot:label>
                  <div @click.stop="">
                    Szeretnék ÁFÁ-s számlát kérni a szolgáltatóktól.
                  </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="newsletter" color="primary" class="pt-0 mt-0">
              <template v-slot:label>
                  <div @click.stop="">
                    Feliratkozok az AutoClean hírlevelére.
                  </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" text @click="cancelEdit">Mégse</v-btn> <!-- :disabled="!formIsValid" -->
            <v-btn color="tertiary" style="color: white" type="submit" :disabled="!formIsValid" @click="closeEditor()">Mentés</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="removeProfileConfirmation" width="500">
      <v-card>
        <v-card-title class="quinary mb-3 card-title">Biztosan törölni szeretnéd?
        </v-card-title>
        <v-card-text>
          Profilod törlési kérelmét 30 napig tudod visszavonni. Ezt követően viszont a profilod véglegesen törlésre kerül. Biztosan elküldöd a törlési kérelmet?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="removeProfileConfirmation = false">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="removeProfile">Törlés</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <strong class="primary-color">Regisztrációs adatok:</strong>
      <hr style="height:8px; visibility:hidden;"/>
      <div class="username-and-email py-0">
        <v-icon class="normal-icons" color="primary" left>mdi-account-circle</v-icon>
        <span class="ml-3" style="width:85%">{{ this.user.name }}
        </span>
      </div>
      <div class="username-and-email py-0">
        <v-icon class="normal-icons" color="primary" left>mdi-email-mark-as-unread</v-icon>
        <span class="ml-3" style="width:85%">{{ this.user.email }}
        </span>
      </div>
      <hr style="height:6px; visibility:hidden;"/>
      <strong class="primary-color">További adatok:</strong>
      <hr style="height:13px; visibility:hidden;"/>
      <div class="element-display-name pb-2" v-if="addressType === 'personal'">
        <v-icon class="icons" color="primary" left>mdi-account</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense class="my-0 py-0" v-model="billingName" label="Név" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pb-2" v-if="addressType === 'company'">
        <v-icon class="icons" color="primary" left>mdi-office-building</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense class="my-0 py-0" v-model="companyName" label="Cégnév" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pt-3 pb-2" v-if="addressType === 'company'">
        <v-icon class="icons" color="primary" left>mdi-card-account-details-outline</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense class="my-0 py-0" v-model="taxNumber" label="Adószám" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pt-3 pb-2">
        <v-icon class="icons" color="primary" left>mdi-numeric</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense class="my-0 py-0" v-model="zip" label="Irányítószám" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pt-3 pb-2">
        <v-icon class="icons" color="primary" left>mdi-domain</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense class="my-0 py-0" v-model="city" label="Település" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pt-3 pb-2">
        <v-icon class="icons" color="primary" left>mdi-home</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense v-model="address" label="Utca, házszám" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="element-display-name pt-3 pb-2">
        <v-icon class="icons" color="primary" left>mdi-cellphone</v-icon>
        <span class="ml-2" style="width:85%">
          <v-text-field hide-details dense v-model="checkPhone" label="Telefonszám" outlined readonly></v-text-field>
        </span>
      </div>
      <div class="afa-element pt-2">
        <v-icon class="normal-icons" color="primary" left>mdi-receipt-text-outline</v-icon>
        <span class="ml-3" style="width:85%">{{ checkAfa }}
        </span>
      </div>
      <hr style="height:20px; visibility:hidden;"/>
      <div>
        <v-btn color="tertiary" style="color: white" @click="openEditor()">
          <v-icon class="mr-1" color="white">mdi-pencil-outline</v-icon>
          Adatok szerkesztése
        </v-btn>
      </div>
      <hr style="height:20px; visibility:hidden;"/>
      <div>
        <!-- <v-btn color="tertiary" style="color: white" @click="openEditor()">Adatok szerkesztése</v-btn> -->
        <v-btn color="quinary" style="color: white" @click="showConfirm" class="text-capitalize">
          <v-icon class="mr-1" color="white">mdi-delete-outline</v-icon>
          Profil törlése
        </v-btn>
      </div>
      <hr style="height:18px; visibility:hidden;"/>
      <span>
        <v-btn class="button-with-link px-0 ml-2 mr-4" small color="tertiary" text @click="openASzF()">
          <span class="tert">ÁSzF</span>
          <v-icon class="pl-1" small color="tertiary">mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn class="button-with-link px-0" small color="tertiary" text @click="openAdatkezelesi()">
          <span class="tert">Adatkezelési szabályzat</span>
          <v-icon class="pl-1" small color="tertiary">mdi-open-in-new</v-icon>
        </v-btn>
      </span>
      <hr style="height:5px; visibility:hidden;"/>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    show: false,
    addressType: 'personal',
    billingName: '',
    zip: null,
    city: null,
    address: null,
    phone: '',
    companyName: '',
    taxNumber: '',
    afa: false,
    newsletter: false,
    rules: {
      billingName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      zip: [
        v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        v => /^[0-9]*$/.test(v) || 'Kérjük adj meg valós irányítószámot.'
      ],
      city: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      address: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      phone: v => /^[0-9\s+-]*$/.test(v) || 'Kérjük adj meg valós telefonszámot.',
      companyName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
      taxNumber: [
        v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        v => /^[0-9-]*$/.test(v) || 'Kérjük adj meg szabályos adószámot.'
      ]
    },
    errors: {},
    removeProfileConfirmation: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    /* ...mapState('userData', ['userExtraData']), mégsem kell használni */

    checkPhone () {
      return this.phone !== '' ? this.phone : 'Nem adtam meg telefonszámot.'
    },
    checkAfa () {
      return this.afa === true ? 'Kérek ÁFÁ-s számlát.' : 'Nem kérek ÁFÁ-s számlát.'
    },
    formIsValid () {
      return (
        (this.addressType === 'personal' && this.billingName && this.zip && this.city && this.address) ||
        (this.addressType === 'company' && this.companyName && this.zip && this.city && this.address && this.taxNumber)
      )
    }
  },
  async mounted () {
    await this.getUser() // lekérjük user-t, be kell várni a legelső adat-megadás utáni mount-nál

    this.showData()
  },
  methods: {
    ...mapActions('userData', ['saveUserData', 'setDeleteProfile']),
    ...mapActions('auth', ['getUser']),

    async logout () {
      await this.$store.dispatch('auth/logout')
      this.$router.replace({ name: 'login' })
    },
    showConfirm () {
      this.removeProfileConfirmation = true
    },
    async removeProfile () {
      await this.setDeleteProfile()
      this.removeProfileConfirmation = false
      this.logout()
    },

    openEditor () {
      this.show = true
    },
    closeEditor () {
      this.show = false
    },
    cancelEdit () {
      this.show = false
    },
    showData () {
      this.addressType = this.user.addressType
      this.billingName = this.user.billingName
      this.zip = this.user.zip
      this.city = this.user.city
      this.address = this.user.address
      this.phone = this.user.phone
      this.companyName = this.user.companyName
      this.taxNumber = this.user.taxNumber
      this.afa = this.user.afa
      this.newsletter = this.user.newsletter
    },
    save () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      const userAdditionalData = {
        addressType: this.addressType,
        billingName: this.billingName,
        zip: this.zip,
        city: this.city,
        address: this.address,
        phone: this.phone,
        companyName: this.companyName,
        taxNumber: this.taxNumber,
        afa: this.afa,
        newsletter: this.newsletter
      }
      if (userAdditionalData.addressType === 'company') {
        userAdditionalData.billingName = ''
      } else if (userAdditionalData.addressType === 'personal') {
        userAdditionalData.companyName = ''
        userAdditionalData.taxNumber = ''
      }
      this.saveUserData(userAdditionalData)

      /* Nem ezt a módszert használjuk, hanem async mounted()-et
      setTimeout(() => {
        this.getUser()
      }, 2000)
      */
    },
    openASzF () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Ugyfel_ASZF-240528.pdf', '_blank')
    },
    openAdatkezelesi () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Adatkezelesi_szabalyzat-240528.pdf', '_blank')
    }
  }
}
</script>

<style lang="sass" scoped>
.card-title
  color: white
.primary-color
  color: var(--v-primary-base)
.message
  font-size: 18px
.element-display-name
  display: flex
  justify-content: left
  align-items: center
.afa-element
  display: flex
  justify-content: left
  align-items: center
  font-size: 17px
.username-and-email
  display: flex
  justify-content: left
  align-items: center
  font-size: 18px
.icons
  position: relative
  left: 7px
.normal-icons
  position: relative
  left: 7px
.profile-data
  width: 100%
.button-with-link
  text-transform: none !important
</style>
