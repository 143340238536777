<template>
  <v-container class="login" fluid>
    <h2 class="mb-8">Bejelentkezés</h2>
    Google fiókkal:
    <img src="@/assets/google.svg" style="cursor: pointer" class="mb-4 mt-4" width="60" @click="googleLogin" />
    <label>vagy:</label>
    <v-form class="login-form pt-3" ref="form" v-model="valid" @submit.prevent="submit" lazy-validation>
      <div class="email-input">
        <v-text-field :rules="[rules.validEmail]" :error-messages="errors.email"
        placeholder="E-mail cím" v-model="email" @input="errors.email = null" validate-on-blur>
        </v-text-field>
      </div>
      <div class="password-input">
        <v-text-field :rules="[rules.passwordRequired]" :error-messages="errors.password"
        placeholder="Jelszó" v-model="password" :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword">
        </v-text-field>
      </div>
      <div class="continue-buttons-div">
        <v-btn color="quaternary" :loading="loading" class="continue-button mx-auto mt-5"
        style="color: white; width: 100%" type="submit">Folytatás</v-btn>
      </div>
    </v-form>
    <div class="register mt-9">
      Nincs még fiókod? <a href="#" @click.prevent="$router.push({ name: 'register' })">Regisztrálj</a> egyszerűen és gyorsan.
    </div>
    <div class="register mt-2">
      Nem emlékszel a jelszavadra? Akkor kattints <a href="#" @click.prevent="$router.push({ name: 'password-reminder' })">ide.</a>
    </div>
    <div class="register aszf-div mt-5">
      Alkalmazásunk használatával elfogadod az <a href="#" @click.prevent="openASzF()">ÁSzF</a>-et
       és az <a href="#" @click.prevent="openAdatkezelesi()">Adatkezelési szabályzat</a>-ot.
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    loading: false,
    rules: {
      passwordRequired: v => !!v || 'Kérjük add meg a jelszavad.',
      validEmail: v => {
        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return emailPattern.test(v) || 'Kérjük adj meg egy valós e-mail címet.'
      }
    },
    valid: true,
    errors: {}
  }),
  methods: {
    ...mapActions('auth', ['login']),

    async submit () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      this.loading = true
      const { email, password } = this
      try {
        await this.login({ email, password })
        this.$router.replace({ name: 'reserve' })
      } catch (e) {
        if (e.response?.data?.message === 'wrong_password') {
          this.errors.password = ['Helytelen a megadott jelszó.']
        } else if (e.response?.data?.message === 'no_user_with_email') {
          this.errors.email = ['Nem található fiók ezzel az e-mail címmel.']
        } else if (e.response?.data?.message === 'delete_profile') {
          this.errors.password = ['Kérelmezted a profilod törlését. 30 napig van lehetőséged a törlést visszavonni, ehhez vedd fel velünk a kapcsolatot.']
        }
      }
      this.loading = false
    },
    googleLogin () {
      window.location.href = `${process.env.VUE_APP_API_URL}/auth/google`
    },
    openASzF () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Ugyfel_ASZF-240528.pdf', '_blank')
    },
    openAdatkezelesi () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Adatkezelesi_szabalyzat-240528.pdf', '_blank')
    }
  }
}
</script>

<style lang="sass" scoped>
.login
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
.register
  font-size: 0.6rem
.aszf-div
  max-width: 305px
  text-align: center
.login-form
  width: 260px !important
.continue-button
  width: 200px !important
.continue-buttons-div
  padding-left: 30px
  padding-right: 30px
</style>
