<template>
  <v-container class="container" fluid>
    <v-dialog v-model="removeFavouriteDialog" width="400">
      <v-card class="favourites-card">
        <v-card-title class="quinary mb-3 px-4 pt-2 pb-2 favourites-card-title">Eltávolítod a kedvencekből?
          <v-icon color="white" right>mdi-star-remove-outline</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 pl-5 pr-4">
          <div class="primary-color">{{ favouriteToRemove.name }}</div>
          <div class="delete-items mb-2 mt-2">
            <v-icon dense color="primary">mdi-map-marker-radius</v-icon>
            <span class="favourite-address ml-2">
              <span class="detail-texts">
                {{ favouriteToRemove.addressPartOne }}
              </span><br>
              <span class="detail-texts">
                {{ favouriteToRemove.addressPartTwo }}
              </span>
            </span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quinary" text @click="cancelTheRemove()">Mégse</v-btn>
          <v-btn color="quinary" style="color: white" @click="removeTheFavourite(favouriteToRemove.id)">Eltávolít</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="no-favourites" v-if="!user.favourites.length">
      <div class="mx-4 my-2">A kedvencek listád még üres.</div>
      <div class="center-the-text mx-4 mt-2 mb-4">Keress a térképen és adj hozzá új kedvenc szolgáltatókat!</div>
      <v-btn color="tertiary" style="color: white" class="mt-5" :to="{ name: 'reserve' }">Tovább a térképre</v-btn>
    </div>
    <div class="got-favourites" v-else>
      <v-list flat class="pt-0">
        <v-list-item-group>
          <div v-for="service in services" :key="service._id">
            <v-list-item v-if="user.favourites.includes(service._id)" class="px-2">
              <v-list-item-content>
                <v-list-item-title>
                  <div class="favourite-display-name py-2">
                    <v-icon class="star-icons" color="amber" left>mdi-star</v-icon>
                    <div>
                      <div class="favourite-details primary-color">{{ service.name }}</div>
                      <div class="favourite-details pictogram-group mb-1 mt-2">
                        <v-icon dense color="primary">mdi-map-marker-radius</v-icon>
                        <span class="favourite-address ml-1">
                          <span class="detail-texts">
                            {{ service.contact.address.slice(0, service.contact.address.indexOf(',') + 1) }}
                          </span><br>
                          <span class="detail-texts">
                            {{ service.contact.address.slice(service.contact.address.indexOf(',') + 2) }}
                          </span>
                        </span>
                      </div>
                      <div class="pictogram-group my-2">
                        <v-icon dense color="primary">mdi-email-mark-as-unread</v-icon>
                        <div class="detail-texts ml-2">
                          {{ service.contact.email }}
                        </div>
                      </div>
                      <div class="pictogram-group my-2">
                        <v-icon dense color="primary">mdi-phone</v-icon>
                        <span class="detail-texts ml-2">
                          {{ service.contact.phone }}
                        </span>
                      </div>
                      <div class="pictogram-group my-2">
                        <v-icon dense color="primary">mdi-web</v-icon>
                        <v-btn x-small text class="button-with-link ml-2 px-0" @click="openLink(service)">
                          <span class="primary-color">Weboldal megtekintése</span>
                          <v-icon class="pl-1" small color="primary">mdi-open-in-new</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <span class="actions">
                    <v-icon class="delete-icon mr-1" color="quinary" @click="removeFavouriteConfirm(service)">mdi-delete-outline</v-icon>
                  </span>
                </v-list-item-title>
                <div class="horizontally-middle">
                  <v-btn class="mb-3" width="200px" color="quaternary" style="color: white" @click="proceed(service)">Foglalás</v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="user.favourites.includes(service._id)"></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
      <v-btn v-if="user.favourites.length <= 4" color="tertiary" style="color: white"
        class="new-favourite-button mt-4 mb-4" :to="{ name: 'reserve' }">
        <v-icon size="25px" left>mdi-plus</v-icon>
        Új kedvenc keresése
      </v-btn>
      <div v-else class="primary-color horizontally-middle pt-3 pb-2">
        <div class="center-the-text maximal-favourites mx-6">
          <v-icon color="primary" dense left>mdi-information-outline</v-icon>
          Maximum 5 kedvencet tudsz felvenni a listádra.
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    commaPosition: 0,
    removeFavouriteDialog: false,
    favouriteToRemove: {}
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('service', ['services']),
    ...mapState('car', ['cars'])
  },
  methods: {
    ...mapActions('auth', ['removeFavourite']),
    ...mapMutations(['setSelectedService']),

    getAddressPartOne (service) {
      this.commaPosition = service.contact.address.indexOf(',')
      return service.contact.address.slice(0, this.commaPosition + 1)
    },
    getAddressPartTwo (service) {
      return service.contact.address.slice(this.commaPosition + 2)
    },
    openLink (service) {
      window.open(service.contact.webpage, '_blank')
    },
    proceed (service) {
      this.setSelectedService(service)
      if (this.cars.length) {
        this.$router.push({ name: 'pre-reserve' })
      } else {
        this.$router.push({ name: 'cars' })
      }
    },
    removeFavouriteConfirm (thisFavourite) {
      this.favouriteToRemove.id = thisFavourite._id
      this.favouriteToRemove.name = thisFavourite.name
      this.favouriteToRemove.addressPartOne = thisFavourite.contact.address.slice(0, thisFavourite.contact.address.indexOf(',') + 1)
      this.favouriteToRemove.addressPartTwo = thisFavourite.contact.address.slice(thisFavourite.contact.address.indexOf(',') + 2)
      this.removeFavouriteDialog = true
    },
    cancelTheRemove () {
      this.removeFavouriteDialog = false
      this.favouriteToRemove = {}
    },
    removeTheFavourite (favouriteToRemoveID) {
      this.removeFavourite(favouriteToRemoveID)
      this.removeFavouriteDialog = false
      this.favouriteToRemove = {}
    }
  }
}
</script>

<style lang="sass" scoped>
.container
  height: 100%
.primary-color
  color: var(--v-primary-base)
.no-favourites
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  height: 100%
.center-the-text
  text-align: center
.favourite-display-name
  width: calc(100vw - 80px)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: flex
  justify-content: left
  align-items: center
.v-list-item__title
  display: flex
  justify-content: space-between
.delete-icon
  margin: 0
  position: absolute
  top: 50%
  right: 2%
  transform: translateY(-50%)
.favourite-details
  width: calc(100vw - 115px)
  white-space: pre-wrap
.theme--light.v-btn--active::before
  opacity: 0 !important
.pictogram-group
  position: relative
  right: 15px
  display: flex
  justify-content: left
  align-items: center
.star-icons
  position: relative
  bottom: 41%
.detail-texts
  font-size: 16px !important
.favourite-address
  line-height: 1
.button-with-link
  text-transform: none !important
  letter-spacing: .01em !important
.horizontally-middle
  display: flex
  justify-content: center
.maximal-favourites
  font-size: 17px
.favourites-card
  .v-card__text
    line-height: 1.2 !important
  .v-btn
    font-size: 17px !important
.favourites-card-title
  font-size: 19px !important
  color: white
.delete-items
  display: flex
  justify-content: left
  align-items: center
</style>
