<template>
  <v-container fluid>
    <v-dialog v-model="show" width="500"> <!-- persistent -->
      <v-form ref="form" @submit.prevent="save" lazy-validation>
        <v-card>
          <v-card-title class="tertiary mb-3 card-title">
            <!--
            <div class="space low-height">Regisztráció utolsó </div>
            <div class="low-height"> </div>
            <div class="low-height">lépése</div>
            -->
            <div class="low-height">Utolsó lépés</div>
            <v-icon color="white" right>mdi-file-edit</v-icon>
          </v-card-title>
          <v-card-text class="pb-0">
            <div class="message">
              <div>A szolgáltatók kötelesek számlát kiállítani az általuk nyújtott szolgáltatásokról,
              ezért kérjük adj meg számlázási nevet és címet, mivel ezek a szolgáltatók
              számára szükséges információk. A későbbiekben bármikor módosíthatod az adataidat a profilodban.</div>
              <!--
              <div>A későbbiekben bármikor módosíthatod az adataidat a profilodban.</div>
              -->
            </div>
            <v-radio-group v-model="addressType" mandatory row>
              <v-radio label="Személyes" color="primary" value="personal" @click="radioSet"></v-radio>
              <v-radio label="Céges" color="primary" value="company" @click="radioSet"></v-radio>
            </v-radio-group>
            <v-text-field v-if="addressType === 'personal'" ref="name" v-model="billingName" label="Név" hint="A számlázási név a valós neved kell legyen." required :rules="[rules.billingName]" :error-messages="errors.billingName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="companyName" label="Cégnév" placeholder="Pl. Tuti Mosó Kft." required :rules="[rules.companyName]" :error-messages="errors.companyName"></v-text-field>
            <v-text-field v-if="addressType === 'company'" v-model="taxNumber" label="Adószám" placeholder="Pl. 12345678-2-08" required :rules="rules.taxNumber" :error-messages="errors.taxNumber"></v-text-field>
            <v-text-field ref="zip" v-model="zip" label="Irányítószám" placeholder="Pl. 9026" required :rules="rules.zip" :error-messages="errors.zip"></v-text-field>
            <v-text-field ref="city" v-model="city" label="Település" placeholder="Pl. Győr" required :rules="[rules.city]" :error-messages="errors.city"></v-text-field>
            <v-text-field ref="address" v-model="address" label="Utca, házszám" placeholder="Pl. Mosós utca 11/B" required :rules="[rules.address]" :error-messages="errors.address"></v-text-field>
            <v-text-field v-model="phone" label="Telefonszám (opcionális)" placeholder="Pl. 06301234567" :rules="[rules.phone]" :error-messages="errors.phone"></v-text-field>
            <v-checkbox v-model="terms" color="primary" required :rules="[rules.terms]" :error-messages="errors.terms">
              <template v-slot:label>
                  <div @click.stop="">
                    Elfogadom az <a href="#" @click.prevent="openASzF()">ÁSzF</a>
                    <v-icon class="small-icon" size="14px" color="primary">mdi-open-in-new</v-icon>-ben és az
                    <a href="#" @click.prevent="openAdatkezelesi()">Adatkezelési szabályzat</a>
                    <v-icon class="small-icon" size="14px" color="primary">mdi-open-in-new</v-icon>-ban leírtakat.
                  </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="afa" color="primary" class="pt-0 mt-0">
              <template v-slot:label>
                  <div @click.stop="">
                    Szeretnék ÁFÁ-s számlát kérni a szolgáltatóktól.
                  </div>
              </template>
            </v-checkbox>
            <v-checkbox v-model="newsletter" color="primary" class="pt-0 mt-0">
              <template v-slot:label>
                  <div @click.stop="">
                    Feliratkozok az AutoClean hírlevelére.
                  </div>
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn class="mb-2" color="tertiary" style="color: white" type="submit" :disabled="!formIsValid" @click="setShow()">
              Tovább a foglaláshoz
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div class="calendar-wrapper pb-15">
      <v-sheet height="100%">
        <v-toolbar flat>
          <v-btn class="today-button" color="tertiary" style="color: white" @click="setToday">Ma</v-btn>
          <v-btn small color="tertiary" style="color: white" class="prev-next-button" @click="prev" :disabled="isPrevDisabled">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="tertiary" style="color: white" class="prev-next-button" @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title class="ml-2" v-if="$refs.calendar">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-calendar
          ref="calendar"
          v-model="focus"
          :now="today"
          :value="today"
          :events="reservable"
          color="primary"
          type="week"
          :locale="locale"
          :short-intervals="false"
          :first-interval="firstInterval"
          :interval-minutes="unitInMinutes"
          :interval-count="intervalCount"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="updateRange"
        >
          <template v-slot:event="{ event }">
            <div class="reserve-label" @click="showReserveConfirmation(event)">
              <v-icon large color="white">mdi-plus-circle-outline</v-icon>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </div>
    <v-dialog v-model="confirmDialog" width="450">
      <v-card>
        <v-card-title class="tertiary mb-3 pl-5 card-title">Foglalásod adatai
          <v-icon class="pl-1" color="white" right>mdi-calendar-text-outline</v-icon>
        </v-card-title>
        <v-card-text v-if="Array.isArray(cars)" class="pb-0 pr-4" style="color:black">
          <div class="pb-1">
            <strong class="primary-color">Időpont és helyszín:</strong>
          </div>
          <div class="pictogram-group my-1">
            <v-icon dense color="primary">mdi-calendar-clock</v-icon>
            <span class="ml-3">
              {{ selectedTimeRange }}
            </span>
          </div>
          <div class="pictogram-group my-1">
            <v-icon dense color="primary">mdi-car-wash</v-icon>
            <span class="ml-3">
              {{ selectedService.name }}
            </span>
          </div>
          <div class="pictogram-group my-1">
            <v-icon dense color="primary">mdi-map-marker-radius</v-icon>
            <span class="address-display ml-3">
              <span>{{ getAddressPartOne(selectedService) }}</span><br>
              <span>{{ getAddressPartTwo(selectedService) }}</span>
            </span>
          </div>
          <hr style="height:9px; visibility:hidden;"/>
          <v-divider></v-divider>
          <hr style="height:9px; visibility:hidden;"/>
          <div class="pb-1">
            <strong class="primary-color"> {{ `Autó${Object.keys(selectedCars).length > 1 ? 'k' : ''} és szolgáltatások:` }} </strong>
          </div>
          <div class="car mb-3" v-for="(services, id) in selectedCars" :key="id">
            <div>
              <v-icon color="primary" left>mdi-car</v-icon>
              <span>{{ getCarDisplayName(getCarById(id)) }}</span>
            </div>
            <div class="service-types mt-2 ml-2">
              <span v-for="(value, service) in services" :key="service">
                <v-chip class="mr-2">{{ getServiceTypeName(service) }}</v-chip>
              </span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="horizontally-middle pb-3">
          <v-btn color="quaternary" style="color: white" width="200px" @click="reserve">Foglalás</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import client from '../axios'
import socket from '../socket'

export default {
  data () {
    const dateFormat = 'YYYY-MM-DD'
    const dateTimeFormat = 'YYYY-MM-DD HH:mm'

    return {
      dateFormat,
      dateTimeFormat,
      locale: 'hu-HU',
      today: moment().format(dateFormat),
      focus: '',
      title: '',
      start: '',
      end: '',
      confirmDialog: false,
      selectedDate: null,
      firstInterval: 0,
      intervalCount: 50,
      reserved: null,
      commaPosition: 0,

      show: false,
      addressType: 'personal',
      billingName: '',
      zip: null,
      city: null,
      address: null,
      phone: '',
      companyName: '',
      taxNumber: '',
      terms: false,
      afa: false,
      newsletter: false,

      errors: {},
      rules: {
        billingName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        zip: [
          v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
          v => /^[0-9]*$/.test(v) || 'Kérjük adj meg valós irányítószámot.'
        ],
        city: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        address: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        phone: v => /^[0-9\s+-]*$/.test(v) || 'Kérjük adj meg valós telefonszámot.',
        companyName: v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
        taxNumber: [
          v => !!v || 'Kérjük töltsd ki ezt a mezőt.',
          v => /^[0-9-]*$/.test(v) || 'Kérjük adj meg szabályos adószámot.'
        ],
        terms: v => !!v || 'Kérjük az elolvasásuk után fogadd el az ÁSzF-et és az Adatkezelési szabályzatot.'
      }
    }
  },

  async mounted () {
    // await this.getUser() // lekérjük user-t, be kell várni a legelső adat-megadás utáni mount-nál

    var something = this.displayName
    if (something === 'nothing') {}

    this.getReservations()
    this.$refs.calendar.checkChange()
    this.focus = this.today
    this.$once('reservables-loaded', () => {
      if (!this.reservable.length) {
        this.next()
      }
    })
    socket.on('new-reservation', (serviceId) => {
      if (serviceId === this.service._id) {
        this.getReservations()
      }
    })
    socket.on('modified-reservation', (serviceId) => {
      if (serviceId === this.service._id) {
        this.getReservations()
      }
    })
  },
  beforeDestroy () {
    socket.removeAllListeners()
  },

  computed: {
    ...mapState(['selectedService', 'selectedCars']),
    ...mapState('service', ['services']),
    ...mapState('car', ['cars']),
    ...mapGetters('service', ['getServiceTypeName']),
    ...mapGetters('car', ['getCarDisplayName', 'getCarById']),
    ...mapState('auth', ['user']),

    formIsValid () {
      return (
        (this.addressType === 'personal' && this.billingName && this.zip && this.city && this.address && this.terms) ||
        (this.addressType === 'company' && this.companyName && this.zip && this.city && this.address && this.taxNumber && this.terms)
      )
    },

    displayName: {
      get () {
        this.setName(this.user.name)
        return this.user.name
      },
      set (value) {
        this.billingName = value
      }
    },

    unit () {
      return this.selectedService.timeUnit
    },
    service () {
      return this.selectedService
    },
    unitInMinutes () {
      return 60 / this.unit
    },
    durationToReserve () {
      let result = 0
      if (!this.cars || !this.cars.length) return result
      const times = this.selectedService.serviceTimes
      for (const carId in this.selectedCars) {
        const { type: carType } = this.cars.find(car => car._id === carId)
        const serviceTypes = Object.keys(this.selectedCars[carId])
        for (const serviceType of serviceTypes) {
          const time = times[serviceType] ? times[serviceType][carType] : 0
          result += time
        }
      }

      return result
    },
    reservable () {
      if (!this.service || !this.start || !this.reserved) {
        return []
      }

      let reservable = []
      const { openingHours } = this.service

      // lefoglalható időpontok előállítása az adott hétre, a nyitvatartás alapján
      const date = this.start.date
      for (const day in openingHours) {
        if (!openingHours[day]) continue
        const from = moment(`${date} ${openingHours[day].from}`).add(day, 'days')
        const to = moment(`${date} ${openingHours[day].to}`).add(day, 'days')
        const duration = moment.duration(to.diff(from))
        const units = duration.asHours() * this.unit

        for (let i = 0; i < units; i++) {
          const start = from.clone().add(this.unitInMinutes * i, 'minutes')
          const end = from.clone().add(this.unitInMinutes * (i + 1), 'minutes')
          if (start.isBefore(moment().add(this.unitInMinutes, 'minutes'))) continue // talán ez mutatja, mennyivel előtte foglalható
          reservable.push({
            start: start.format(this.dateTimeFormat),
            end: end.format(this.dateTimeFormat),
            isModified: false
          })
        }
      }

      /* a szolgáltató által pluszba hozzáadott lefoglalható időpontok,
         pl. egy ledolgozandó szombati munkanap, modifiedDates alapján */
      if (this.selectedService.modifiedDates.length) {
        for (const index in this.selectedService.modifiedDates) {
          if (this.selectedService.modifiedDates[index].whatType === 'add') {
            const modifiedStart = moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].from)
            const modifiedEnd = moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].to)
            // csak akkor adjuk hozzá, ha az adott héten van
            if (modifiedEnd.isBetween(moment(date), moment(date).add(7, 'days'), undefined, '[]')) {
              const durationInMinutes = Math.abs(modifiedEnd - modifiedStart) / 60000
              const modifiedUnits = Math.floor(durationInMinutes / this.unitInMinutes)
              if (modifiedUnits) {
                for (let i = 0; i < modifiedUnits; i++) {
                  const thisStart = modifiedStart.clone().add(this.unitInMinutes * i, 'minutes')
                  const thisEnd = modifiedStart.clone().add(this.unitInMinutes * (i + 1), 'minutes')
                  if (thisStart.isBefore(moment().add(this.unitInMinutes, 'minutes'))) continue // talán ez mutatja, mennyivel előtte foglalható
                  reservable.push({
                    start: thisStart.format(this.dateTimeFormat),
                    end: thisEnd.format(this.dateTimeFormat),
                    isModified: true
                  })
                }
              }
            }
          }
        }
      }

      // időegységek kezdete / vége beállítás
      const times = reservable.map(({ start }) => moment(start).format('HH:mm').replace(':', ''))
      const minStart = Math.min(...times)
      if (Number.isInteger(minStart)) {
        const startTime = minStart.toString()
        const startHour = startTime.length === 3 ? Number(startTime[0]) : Number(startTime.substr(0, 2))
        this.setFirstInterval(startHour * this.unit - 1)
      }
      const maxStart = Math.max(...times)
      if (Number.isInteger(maxStart)) {
        const endTime = maxStart.toString()
        const endHour = endTime.length === 3 ? Number(endTime[0]) : Number(endTime.substr(0, 2))
        this.setIntervalCount(endHour * this.unit - this.firstInterval + this.unit + 1)
      }

      // ne mutassuk azokat a dátumokat, amelyekre már nem foglalhatunk
      reservable = reservable.filter(({ start }) => {
        const end = moment(start).add(this.durationToReserve, 'minutes')
        const range = moment.range([start, end])
        // amelyek már túl későn vannak
        const weekday = moment(start).day()
        const whichDay = weekday !== 0 ? weekday - 1 : weekday + 6 // a vasárnap miatt külön vizsgálat kell
        if (this.selectedService.openingHours[whichDay]) {
          const closingTime = this.selectedService.openingHours[whichDay].to
          const closingDate = moment(`${moment(start).format('YYYY-MM-DD')} ${closingTime}`)
          const tooLate = closingDate.isBefore(end)
          if (tooLate) return false
        }
        // amelyek már le vannak foglalva
        for (const reserved of this.reserved) {
          const doesIntersect = range.intersect(
            moment.range([
              moment(reserved.date),
              moment(reserved.date).add(reserved.duration, 'minutes')
            ])
          )
          if (doesIntersect) return false
        }
        /* amelyeket a szolgáltató kivett a lefoglalható időpontjai közül
           pl. ünnep, betegség, szabadság, vagy valami probléma miatti zárvatartás okán,
           vagy akár az általa létrehozott saját foglalásai miatt, modifiedDates alapján */
        if (this.selectedService.modifiedDates.length) {
          for (const index in this.selectedService.modifiedDates) {
            if (this.selectedService.modifiedDates[index].whatType === 'remove') {
              const doesIntersect = range.intersect(
                moment.range([
                  moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].from),
                  moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].to)
                ])
              )
              if (doesIntersect) return false
            }
          }
        }

        return true
      })

      // ne mutassuk azokat a modifiedDates dátumokat, amelyekre már nem foglalhatunk, mert túl későn vannak
      if (this.selectedService.modifiedDates.length) {
        for (const index in this.selectedService.modifiedDates) {
          if (this.selectedService.modifiedDates[index].whatType === 'add') {
            const thisModifiedEnd = moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].to)
            if (thisModifiedEnd.isBetween(moment(date), moment(date).add(7, 'days'), undefined, '[]')) {
              reservable = reservable.filter(({ start, isModified }) => {
                if (moment(start).isBetween(
                  moment(this.selectedService.modifiedDates[index].date + ' ' + this.selectedService.modifiedDates[index].from),
                  thisModifiedEnd, undefined, '[]')
                ) {
                  const thisReservableEnd = moment(start).add(this.durationToReserve, 'minutes')
                  const itIsTooLate = thisModifiedEnd.isBefore(thisReservableEnd)
                  if (itIsTooLate && isModified) {
                    return false
                  }
                }
                return true
              })
            }
          }
        }
      }

      this.$emit('reservables-loaded')

      return reservable
    },
    isPrevDisabled () {
      return moment().isBetween(moment(this.start.date), moment(this.end.date).add(1, 'days'), undefined, '[]')
    },
    selectedTimeRange () {
      if (this.selectedDate) {
        return `${this.selectedDate} - ${moment(this.selectedDate).add(this.durationToReserve, 'minutes').format('HH:mm')}`
      }
      return ''
    }
  },

  methods: {
    ...mapMutations(['setNotification', 'setSelectedService']),
    ...mapActions('reservation', ['addReservation']),
    ...mapActions('userData', ['saveUserData']),
    ...mapActions('auth', ['getUser']),

    getReservations () {
      client.get(`/services/${this.selectedService._id}/reservations-for-calendar`).then(({ data }) => {
        this.reserved = data.map(reservation => ({
          ...reservation,
          date: moment(reservation.date).format('YYYY-MM-DD HH:mm')
        }))
      })
    },
    setFirstInterval (val) {
      this.firstInterval = val
    },
    setIntervalCount (val) {
      this.intervalCount = val
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    updateRange ({ start, end }) {
      this.start = start
      this.end = end

      const yr = this.start.year
      const startMonth = this.start.month
      const endMonth = this.end.month

      const mNames = [
        'Január',
        'Február',
        'Március',
        'Április',
        'Május',
        'Június',
        'Július',
        'Augusztus',
        'Szeptember',
        'Október',
        'November',
        'December'
      ]

      const mNamesShort = [
        'Jan',
        'Feb',
        'Márc',
        'Ápr',
        'Máj',
        'Jún',
        'Júl',
        'Aug',
        'Szept',
        'Okt',
        'Nov',
        'Dec'
      ]

      if (startMonth !== endMonth && startMonth !== 12) {
        this.title = `${mNamesShort[startMonth - 1]} - ${mNamesShort[startMonth]}, ${yr}`
      } else {
        this.title = `${mNames[startMonth - 1]}, ${yr}`
      }
    },
    setToday () {
      this.focus = ''
    },
    showReserveConfirmation (event) {
      this.selectedDate = event.start
      this.confirmDialog = true
    },
    getAddressPartOne (service) {
      this.commaPosition = service.contact.address.indexOf(',')
      return service.contact.address.slice(0, this.commaPosition + 1)
    },
    getAddressPartTwo (service) {
      return service.contact.address.slice(this.commaPosition + 2)
    },
    radioSet () {
      if (this.addressType === 'personal') {
        this.companyName = ''
        this.taxNumber = ''
      }
    },
    setShow () {
      this.show = false
    },
    setName (userName) {
      this.billingName = userName
    },
    save () {
      this.errors = {}
      if (!this.$refs.form.validate()) return
      const userAdditionalData = {
        addressType: this.addressType,
        billingName: this.billingName,
        zip: this.zip,
        city: this.city,
        address: this.address,
        phone: this.phone,
        companyName: this.companyName,
        taxNumber: this.taxNumber,
        afa: this.afa,
        newsletter: this.newsletter
      }
      if (userAdditionalData.addressType === 'company') {
        userAdditionalData.billingName = ''
      }
      this.saveUserData(userAdditionalData)

      /* Nem ezt a módszert használjuk, hanem async mounted()-et
      setTimeout(() => {
        this.getUser()
      }, 2000)
      */
    },
    openASzF () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Ugyfel_ASZF-240528.pdf', '_blank')
    },
    openAdatkezelesi () {
      window.open('https://beta.autoclean.hu/assets/files/AutoClean-Adatkezelesi_szabalyzat-240528.pdf', '_blank')
    },

    async reserve () {
      await this.getUser()
      if (this.user.completeProfile) {
        // this.show = false

        const reservable = this.reservable.find(({ start }) => start === this.selectedDate)
        if (!reservable) {
          this.setNotification('Ez az időpont sajnos már nem elérhető. Kérjük válassz másik időpontot!')
          this.confirmDialog = false
          return
        }
        const date = new Date(this.selectedDate)
        date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        await this.addReservation({
          date: moment(date).format('YYYY-MM-DD HH:mm'),
          cars: this.selectedCars,
          service: this.selectedService,
          duration: this.durationToReserve
        })
        this.confirmDialog = false
        this.setNotification('Sikeres foglalás!')
        this.setSelectedService(null)
        this.$router.replace({ name: 'reservations', params: { current: 0 } })
      } else {
        this.show = true
      }

      /*
      const reservable = this.reservable.find(({ start }) => start === this.selectedDate)
      if (!reservable) {
        this.setNotification('Ez az időpont sajnos már nem elérhető. Kérjük válassz másik időpontot!')
        this.confirmDialog = false
        return
      }
      const date = new Date(this.selectedDate)
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
      await this.addReservation({
        date: moment(date).format('YYYY-MM-DD HH:mm'),
        cars: this.selectedCars,
        service: this.selectedService,
        duration: this.durationToReserve
      })
      this.confirmDialog = false
      this.setNotification('Sikeres foglalás!')
      this.setSelectedService(null)
      this.$router.replace({ name: 'reservations', params: { current: 0 } })
      */
    }
  }
}
</script>

<style lang="sass">
.primary-color
  color: var(--v-primary-base)
.calendar-wrapper
  height: 80vh
.v-event-timed-container
  margin-right: 0 !important
.reserve-label
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  font-weight: bold
.v-calendar-daily_head-day-label
  font-size: 10px !important
.v-btn--fab.v-size--default
  height: 35px !important
  width: 35px !important
.today-button
  padding: 0
  margin-left: -16px !important
  height: 40px !important
  width: 45px !important
  min-width: 1px !important
  font-weight: 400 !important
.prev-next-button
  margin-left: 5px
  padding: 0
  min-width: 1px !important
  width: 35px !important
  height: 40px !important
.pictogram-group
  display: flex
  justify-content: left
  align-items: center
.address-display
  line-height: 1.2
.horizontally-middle
  display: flex
  justify-content: center
.v-toolbar__content
  padding-right: 0px !important
.v-toolbar__title
  font-size: 1.2rem !important
.card-title
  color: white
.space
  white-space: pre
.message
  font-size: 18px
.low-height
  line-height: 130%
.small-icon
  padding-left: 2px
@media only screen and (min-width: 601px)
  .message *
    display: inline
</style>
