import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import PasswordReminder from '../views/PasswordReminder.vue'
import Reserve from '../views/Reserve.vue'
import Calendar from '../views/Calendar.vue'
import Reservations from '../views/Reservations.vue'
import PreReserve from '../views/PreReserve.vue'
import Cars from '../views/Cars.vue'
import Profile from '../views/Profile.vue'
import Favourites from '../views/Favourites.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/password-reminder',
    name: 'password-reminder',
    component: PasswordReminder
  },
  {
    path: '/',
    name: 'reserve',
    component: Reserve,
    meta: {
      title: 'Szolgáltató kereső'
    }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: {
      title: 'Időpont foglalás'
    }
  },
  {
    path: '/reservations',
    name: 'reservations',
    component: Reservations,
    meta: {
      title: 'Foglalásaim'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Profilom'
    }
  },
  {
    path: '/cars',
    name: 'cars',
    component: Cars,
    meta: {
      title: 'Autóim'
    }
  },
  {
    path: '/pre-reserve',
    name: 'pre-reserve',
    component: PreReserve,
    meta: {
      title: 'Autók és szolgáltatások'
    }
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: Favourites,
    meta: {
      title: 'Kedvencek'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (
    !store.state.auth.isLoggedIn &&
    to.matched.some(record => !['login', 'register', 'password-reminder'].includes(record.name))
  ) {
    if (await store.dispatch('auth/checkIfLoggedIn')) {
      next()
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
